<template>
  <v-btn text small class="px-1 text-nonecase" v-if="blocked" v-bind="params.attrs" v-on="params.on">
    <slot></slot>
    <v-tooltip bottom>
      <template right v-slot:activator="{ on, attrs }">
        <i v-cHex="'#ffa100'" class="absolute" v-i:ic#lock-open#16 v-bind="attrs" v-on="on" style="top: -6px; right: -18px;"></i>
      </template>
      <p class="m-0 mt-n1 p-0 fs-7pt f-sanspro fw-600 line-height-0 text-left">
        <i v-i:ic#lock-open#12></i>
        Funcionalidade liberada apenas equipe
      </p>
    </v-tooltip>
  </v-btn>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "tabblocked",
    components: {},
    props: {
      params: { default: null, type: Object },
      color: { default: "#000000", type: String },
      itemName: { default: "item name", type: String },
      equipeID: { default: "equipeID", type: String },
    },
    computed: {
      blocked() {
        if(this.equipe == null) return false;
        return (this.equipe.pessoas[this.userLog.id] != undefined);
      }
    },
    data(){
      return {
        loading: false,
        refresh: 0,
        equipe: null,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      rdb.ref('/usuarios/equipes/'+this.equipeID).on('value', function(snapshot) {
        self.equipe = snapshot.val();
        console.log("self.equipe",self.equipe);
      });

    },
    methods:{

      build() {
          var self = this;
      },

      click() {
        console.log("click");
      }
    }
  }
</script>

<style scoped>
</style>
