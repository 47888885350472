<template>
  <v-card flat>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar dense flat>
      <btnmenuitem title="Inscrição seleção docente - UCs Live 2022/1" idb="ic" icon="fact-check" class="me-3" @click="$emit('modelToggle','inscricao')"/>
      <btnmenuitem title="Administração" icon="gear" class="me-3" equipeID="-Mo3BOHRsHxCu3djFJ4J" :itens="itensArea" @click="itemClick"/>
    </v-toolbar>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import btnlocked from "./btn-locked.vue";
import btnmenuitem from "./btn-menu-item.vue";
import XLSX from 'xlsx';
import moment from 'moment';

export default {
  name: "topmenu",
  components: { btnlocked, btnmenuitem },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      itensArea: [
        { title: "Indicações", idb: "duo", icon: "target", target: "indicacoes" },
        { title: "Indicações por UCDs", idb: "duo", icon: "target", target: "indicacoesUCDs" },
        { title: "separator" },
        { title: "Exportar Planilha", idb: "duo", icon: "download", target: "exportar" },
        { title: "Importação", idb: "duo", icon: "upload", target: "importacao" },
      ]
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    fazAlgo() {
      console.log("fazAlgo");
    },

    itemClick(value) {
      if(value == 'exportar') {
        console.log("exportar");
        this.exportar();
        return;
      }
      this.$emit('modelToggle',value);
    },

    getNomes(vet,inscricoes,callback) {
      var self = this;
      if(inscricoes.length % 25 == 0) {
        console.log("gerarPlanilha",inscricoes.length);
      }
      if(inscricoes.length == 0) {
        callback(vet);
      } else {
        var insc = inscricoes.pop();
        rdb.ref('/usuarios/pessoas/'+insc.id).once('value',function(snapshot) {
          var user = snapshot.val();
          insc.nome = user.nome;
          insc.email = user.email;
          insc.cpf = user.cpf;
          delete insc.id;
          vet.push(insc);
          self.getNomes(vet,inscricoes,callback);
        });
      }
    },

    gerarPlanilhaSemRecursao(areas,ucs,inscricoes,vet) {
      for(var idx in inscricoes) {
        var insc = inscricoes[idx];
        if(insc.experienciasEAD != undefined) {
          insc.experienciasEAD = insc.experienciasEAD.join(" | ");
        }
        insc.areasAtuacao = "";
        for(var keyA in insc.areas) {
          insc.areasAtuacao = insc.areasAtuacao + areas[keyA].sigla + ";";
        }
        delete insc.areas;
        if(insc.ucs20221Sel != undefined) {
          for(var i in insc.ucs20221Sel) {
            var uc = ucs[i];
            if(uc != undefined) {
              insc.ucd20221 = uc.nome;
              insc.ucdAreas = "";
              for(var keyA in uc.areas) {
                insc.ucdAreas = insc.ucdAreas + uc.areas[keyA].sigla + ";";
              }
              var elem = Object.assign({},insc);
              delete elem.ucs20221Sel;
              delete elem.ucs20222Sel;
              vet.push(elem);
            }
          }
        }
        insc.ucd20221 = "";
        if(insc.ucs20222Sel != undefined) {
          for(var i in insc.ucs20222Sel) {
            var uc = ucs[i];
            if(uc != undefined) {
              insc.ucd20222 = uc.nome;
              insc.ucdAreas = "";
              for(var keyA in uc.areas) {
                insc.ucdAreas = insc.ucdAreas + uc.areas[keyA].sigla + ";";
              }
              var elem = Object.assign({},insc);
              delete elem.ucs20221Sel;
              delete elem.ucs20222Sel;
              vet.push(elem);
            }
          }
        }
      }
      this.loading = false;
      console.log("vet",vet);
      const fileName = "appIntegra-INSCRICOES-UCDs"+moment().format()+".xlsx";
      const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      const wb = XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "appIntegra-INSCRICOES-UCDs");
      XLSX.writeFile(wb, fileName);
    },

    gerarPlanilha(areas,ucs,inscricoes,vet) {
      if(inscricoes.length % 25 == 0) {
        console.log("gerarPlanilha",inscricoes.length);
      }
      if(inscricoes.length == 0) {
        this.loading = false;
        console.log("vet",vet);
        const fileName = "appIntegra-INSCRICOES-UCDs"+moment().format()+".xlsx";
        const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
        const wb = XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "appIntegra-INSCRICOES-UCDs");
        XLSX.writeFile(wb, fileName);
      } else {
        var insc = inscricoes.pop();
        if(insc.experienciasEAD != undefined) {
          insc.experienciasEAD = insc.experienciasEAD.join(" | ");
        }
        insc.areasAtuacao = "";
        for(var keyA in insc.areas) {
          insc.areasAtuacao = insc.areasAtuacao + areas[keyA].sigla + ";";
        }
        delete insc.areas;
        if(insc.ucs20221Sel != undefined) {
          for(var i in insc.ucs20221Sel) {
            var uc = ucs[i];
            if(uc != undefined) {
              insc.ucd20221 = uc.nome;
              insc.ucdAreas = "";
              for(var keyA in uc.areas) {
                insc.ucdAreas = insc.ucdAreas + uc.areas[keyA].sigla + ";";
              }
              var elem = Object.assign({},insc);
              delete elem.ucs20221Sel;
              delete elem.ucs20222Sel;
              vet.push(elem);
            }
          }
        }
        insc.ucd20221 = "";
        if(insc.ucs20222Sel != undefined) {
          for(var i in insc.ucs20222Sel) {
            var uc = ucs[i];
            if(uc != undefined) {
              insc.ucd20222 = uc.nome;
              insc.ucdAreas = "";
              for(var keyA in uc.areas) {
                insc.ucdAreas = insc.ucdAreas + uc.areas[keyA].sigla + ";";
              }
              var elem = Object.assign({},insc);
              delete elem.ucs20221Sel;
              delete elem.ucs20222Sel;
              vet.push(elem);
            }
          }
        }
        this.gerarPlanilha(areas,ucs,inscricoes,vet);
      }
    },

    exportar() {
      var self = this;
      var areas, ucs;
      this.loading = true;
      rdb.ref('/curriculo/areas').once('value',function(snapshot) {
        areas = snapshotValToArray(snapshot.val());
        console.log("areas",areas);

        rdb.ref("/curriculo/ofertas/ucsLive/").once('value',function(snapshot) {
          ucs = snapshot.val();
          console.log("ucs",ucs);

          rdb.ref("/curriculo/2021S2/inscricoesUCD/").once('value',function(snapshot) {
            var lista = snapshot.val();
            var inscricoes = [];
            for(var idx in lista) {
              lista[idx].id = idx;
              inscricoes.push(lista[idx]);
            }
            self.getNomes([], inscricoes, function(inscricoes) {
              self.gerarPlanilhaSemRecursao(areas,ucs,inscricoes,[]);
            })
          });
        });
      });
    },

  }
}
</script>

<style scoped>
</style>
